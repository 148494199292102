import { Component } from 'react';
import PropTypes from 'prop-types';
import FormUtils from '@App/utils/form';
import I18n from 'i18n-js';
import TextInput from '@App/components/form/TextInput';
import classNames from 'classnames';
import GraphQLUtils from '@App/utils/graphql';
import Mutation from '@App/components/api/Mutation';
import EditMyUserInfoMutation from '@App/api/mutation/EditMyUserInfo.graphql';
import SelectCountryISO from '@App/components/form/SelectCountryISO';
import SelectUserSector from '@App/components/form/User/SelectUserSector';
import SelectUserType from '@App/components/form/User/SelectUserType';

class EditMyUserInfo extends Component {
    static propTypes = {
        profile: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            country: PropTypes.string,
            phoneNumber: PropTypes.string,
            type: PropTypes.string.isRequired,
            sector: PropTypes.string,
        }).isRequired,
        mutate: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        success: PropTypes.bool,
        errors: PropTypes.object,
    };

    static defaultProps = {
        loading: false,
        errors: {},
        success: null,
    };

    constructor(props) {
        super(props);

        const { firstName, lastName, country, phoneNumber, type, sector } = props.profile;

        this.state = {
            form: {
                firstName,
                lastName,
                country,
                phoneNumber,
                type,
                sector,
            },
        };

        this.handleFieldChange = (fieldName, callback = null) => value => FormUtils.handleFieldChange.bind(this)('form', fieldName, value, callback);
        this.onSubmit = this.onSubmit.bind(this);
        this.getErrors = this.getErrors.bind(this);
        this.linkState = this.linkState.bind(this);
    }

    /**
     * @param {String} path
     */
    getErrors(path) {
        return this.props.errors[path] || [];
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.props.loading) {
            return;
        }

        const { form } = this.state;
        const { mutate } = this.props;

        mutate({ payload: form });
    }

    /**
     * @param {String} field field name to link
     */
    linkState(field, callback = null) {
        return {
            value: this.state.form[field],
            onChange: this.handleFieldChange(field, callback),
            errors: this.getErrors(field),
        };
    }

    render() {
        const { loading, success } = this.props;

        return (
            <form onSubmit={this.onSubmit}>
                <div className="row">
                    <div className="col-sm-6 col-lg-3 offset-lg-1">
                        <TextInput {...this.linkState('firstName')}
                            label={I18n.t('page.my_account.edit_profile.form.firstName')}
                            id="firstName"
                            required
                        />
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <TextInput {...this.linkState('lastName')}
                            label={I18n.t('page.my_account.edit_profile.form.lastName')}
                            id="lastName"
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-lg-3 offset-lg-1">
                        <SelectCountryISO value={this.state.form['country']} disabled={true}
                            label={I18n.t('page.my_account.edit_profile.form.country')}
                            id="country"
                        />
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <TextInput {...this.linkState('phoneNumber')}
                            label={I18n.t('page.my_account.edit_profile.form.phoneNumber')}
                            id="phoneNumber"
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-lg-3 offset-lg-1">
                        <SelectUserType {...{
                            ...this.linkState('type'),
                            onChange: (value) => {
                                this.linkState('type', () => {
                                    if (this.linkState('type').value === 'individual') {
                                        this.setState({
                                            ...this.state, form: {...this.state.form, sector: null}
                                        });
                                    }
                                }).onChange(value);
                            }
                        }}
                        label={I18n.t('page.my_account.edit_profile.form.type')}
                        id="type"
                        required
                        />
                    </div>
                </div>
                {this.linkState('type').value === 'professional' && <>
                    <div className="row">
                        <div className="col-sm-6 col-lg-3 offset-lg-1">
                            <SelectUserSector {...this.linkState('sector')}
                                label={I18n.t('page.my_account.edit_profile.form.sector')}
                                id="sector"
                                displayEmptyOptions
                                required
                            />
                        </div>
                    </div>
                </>}

                <div className="row">
                    <div className="col-sm-12 col-lg-6 offset-lg-1">
                        {success === true && <div className="alert">
                            <span>{I18n.t('page.my_account.edit_profile.success')}</span>
                        </div>}

                        {success === false && <div className="alert alert--error">
                            <span>{I18n.t('page.my_account.edit_profile.error')}</span>
                        </div>}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 offset-lg-4">
                        <button
                            type="submit"
                            className={classNames('btn btn--primary btn--full', { disabled: loading })}
                            disabled={loading}
                        >{I18n.t('page.my_account.edit_profile.form.submit')}</button>
                    </div>
                </div>
            </form>
        );
    }
}

export default function EditMyUserInfoWithMutation(props) {
    return <Mutation
        {...props}
        component={EditMyUserInfo}
        mutation={EditMyUserInfoMutation}
        loadingComponent={EditMyUserInfo}
        errorComponent={EditMyUserInfo}
        mapResult={data => ({ success: !!data.editMyInfo })}
        mapLoading={() => ({ loading: true, success: null })}
        mapError={(error) => {
            const errorsByPath = GraphQLUtils.errorsByPath((error && error.graphQLErrors) || []);

            return {
                success: false,
                errors: {
                    firstName: errorsByPath['firstName'] || [],
                    lastName: errorsByPath['lastName'] || [],
                    country: errorsByPath['country'] || [],
                    phoneNumber: errorsByPath['phoneNumber'] || [],
                    type: errorsByPath['type'] || [],
                    sector: errorsByPath['sector'] || [],
                }
            };
        }}
    />;
}
