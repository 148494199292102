import i18n from 'i18n-js';

export default function OpenFiltersButton({ isOpen, setOpen }) {
    return (
        <button 
            onClick={() => setOpen(!isOpen)}
            className={`filters__button ${isOpen ? 'filters__button--active' : ''}`.trim()}
        >
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                <path d="M22 3H2l8 9.46v6.54l4 2v-8.54L22 3z"/>
            </svg>
            {i18n.t('search.filters.title')}
        </button>
    );
}
