import { TrackVersionPropTypes } from '@App/components/model/TrackVersion';
import I18n from 'i18n-js';

function TrackVersionProjects({
    version,
}) {
    if (version.projects?.length > 0) {
        const projectLabels = version.projects.map(project => project.name).join(', ');
        const label = version.projects.length > 1 ?
            I18n.t('model.track.versions.inProjects', {projects: projectLabels}) :
            I18n.t('model.track.versions.inProject', {project: projectLabels});

        return <span className="tooltip projects" data-tooltip={label}>
            <i className="icon icon--add"></i>
            <span className="sr-only">{label}</span>
        </span>;
    }

    return null;
}

TrackVersionProjects.propTypes = {
    version: TrackVersionPropTypes,
};

export default TrackVersionProjects;
