import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import { useEffect } from 'react';
import { useDebounce} from '@App/hooks/useDebounce';

export default function RangeSlider({
    value,
    min = 0,
    max = 500,
    step = 15,
    allowSameValues = true,
    draggableTrack = true,
    // formatLabel = () => '',
    onChange,
    onChangeComplete,
}) {
    /**
     * Verify the values are within the range
     * If the min value is undefined, null or NaN, set to min of range
     * If the max value is undefined, null or NaN, set to max of range
     */
    useEffect(() => {
        const newValue = { 
            min: Math.max(value.min, min),
            max: Math.min(value.max, max)
        }; // bounds
        
        if ([undefined, null].includes(value.min) || isNaN(value.min)) {
            newValue.min = min;
        }
        
        if ([undefined, null].includes(value.max) || isNaN(value.max)) {
            newValue.max = max;
        }
        
        if (value.min !== newValue.min || value.max !== newValue.max) {
            onChange(newValue);
        }
    }, [value, min, max, onChange]);

    const debounceValue = useDebounce(value);
    useEffect(() => {
        onChangeComplete?.(debounceValue);
    }, [onChangeComplete, debounceValue]);

    return (
        <InputRange
            value={value}
            minValue={min}
            maxValue={max}
            step={step}
            allowSameValues={allowSameValues}
            draggableTrack={draggableTrack}
            onChange={onChange}
        />
    );
}

RangeSlider.propTypes = {
    ...InputRange.PropTypes,
    onChange: PropTypes.func,
    onChangeComplete: PropTypes.func,
    value: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number
    }).isRequired,
};
