import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import GetTempos from '@App/api/query/GetTempos.graphql';
import Loader from '@App/components/ui/Loader';
import { setTempos as setTemposAction } from '@App/store/actions/search';

export default function TempoFilter() {
    const storedTempos = useSelector(state => state.search.tempos);
    
    const [selectedTempos, setSelectedTempos] = useState(storedTempos ?? []);
    
    const dispatch = useDispatch();
    const storeTempos = useCallback(
        (tempos) => dispatch(setTemposAction(tempos)),
        [dispatch],
    );

    // get updates from the store
    useEffect(() => {
        if (storedTempos.join(',') !== selectedTempos.join(',')) {
            setSelectedTempos(storedTempos);
        }
    // dont rerender on state update, else you'll have an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedTempos]);

    const { error, loading, data = []} = useQuery(GetTempos);
    
    const handleTempoChange = (value) => {
        setSelectedTempos(prev => {
            if (prev.includes(value)) {
                return prev.filter(v => v !== value);
            }
            return [...prev, value];
        });
    };

    // store it once the set is done to not recalculate the filter function
    useEffect(() => {
        storeTempos(selectedTempos);
    }, [selectedTempos, storeTempos]);

    return (
        <div className="filters__inline">
            <div className="filters__label">Tempo</div>
            <div className="filters__inline__radios filters__inline__radios--tempos">
                {loading && <Loader />}
                {!loading && data?.tempos?.map?.(({ id, name }) => (
                    <label 
                        key={id}
                        className={`chip chip--filters ${selectedTempos.includes(id) ? 'chip--active' : ''}`.trim()}
                        style={{cursor: 'pointer'}}
                    >
                        <input
                            type="checkbox" 
                            value={id}
                            hidden
                            onChange={e => handleTempoChange(e.target.value)}
                            checked={selectedTempos.includes(id)}
                        />
                        {name}
                    </label>
                ))}
            </div>
        </div>
    );
}
