import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { removeFromIncludedTags, removeFromExcludedTags } from '@App/store/actions/search';
import GetTagName from '@App/api/query/GetTagName.graphql';
import Loader from '@App/components/ui/Loader';
import Query from '@App/components/api/Query';

function TagName({ name }) {
    return name ? <span>{name}</span> : <Loader />;
}

function SearchTag({ id, excluded }) {
    const dispatch = useDispatch();
    
    const remove = () => {
        dispatch(excluded ? removeFromExcludedTags(id) : removeFromIncludedTags(id));
    };

    return (
        <div className="search-items--item search-tag">
            <Query
                component={TagName}
                query={GetTagName}
                variables={{ id }}
                mapResult={data => ({ name: data.tag.name })}
                mapLoading={() => ({ name: null })}
            />
            <button type="button" className="search-items--remove" onClick={remove}>
                <i className="icon icon--clear"></i>
            </button>
        </div>
    );
}

SearchTag.propTypes = {
    id: PropTypes.string.isRequired,
    excluded: PropTypes.bool
};

export default SearchTag;
