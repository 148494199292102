import { useAudioApi } from '@App/hooks/useAudioApi';
import { Component, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { playTrack, seekTrack } from '@App/store/actions/player';
import Anchor from '@App/components/navigation/Anchor';
import WaveformByUrl from '@App/components/model/WaveformByUrl';
import TrackButtonBar from '@App/components/model/TrackButtonBar';
import TrackVersionsButton from '@App/components/model/track/TrackVersionsButton';
import TrackTags from '@App/components/model/track/TrackTags';
import { formatDuration } from '@App/utils';
import { click } from '@App/store/actions/searchAnalytics';
import { trackPlayed } from '@App/store/actions/stats';
import InsightsEvent from '@App/searchAnalytics/insightsEvent';
import { SIZE_LG } from '@App/service/MobileWatcher';
import { isLoadingTrack, isPausedTrack, isPlayingTrack } from '@App/store/reducers/player';
import { get } from '@App/container';
import Seeker from '@App/components/ui/Seeker';
import Highlight from '@App/model/similarSearch/Highlight';
import InProject from '@App/model/version/InProject';
import TrackVersionProjects from '@App/components/model/track/TrackVersionProjects';

class TrackVersion extends Component {
    static propTypes = {
        version: PropTypes.shape({
            reference: PropTypes.string.isRequired,
            label: PropTypes.string,
            waveformUrl: PropTypes.string,
            duration: PropTypes.number.isRequired,
            audioFileUrl: PropTypes.string.isRequired,
            main: PropTypes.bool.isRequired,
            availableForSimilarSearch: PropTypes.bool.isRequired,
            adminEditUrl: PropTypes.string,
            track: PropTypes.shape({
                reference: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                nbVersions: PropTypes.number.isRequired,
                createdAt: PropTypes.string.isRequired,
                album: PropTypes.shape({
                    reference: PropTypes.string.isRequired,
                    coverAsThumbnail: PropTypes.string.isRequired,
                }),
            }).isRequired,
            highlights: PropTypes.arrayOf(Highlight),
            projects: PropTypes.arrayOf(InProject),
        }),
        onPlay: PropTypes.func.isRequired,
        onSeek: PropTypes.func.isRequired,
        onPlayAt: PropTypes.func.isRequired,
        mobile: PropTypes.bool.isRequired,
        active: PropTypes.bool.isRequired,
        projectId: PropTypes.string,
        searchResultsPosition: PropTypes.number,
        isPlaying: PropTypes.bool,
        isPaused: PropTypes.bool,
        isLoading: PropTypes.bool,
        read: PropTypes.bool.isRequired,
        showAllTags: PropTypes.bool.isRequired,
        playTime: PropTypes.number,
    };

    static defaultProps = {
        projectId: null,
        searchResultsPosition: null,
        isPlaying: false,
        isPaused: false,
        isLoading: false,
        playTime: 0,
    };

    constructor() {
        super();

        this.state = {
            showTags: false,
        };

        this.onPlay = this.onPlay.bind(this);
        this.onSeek = this.onSeek.bind(this);
        this.onShowTags = this.onShowTags.bind(this);
    }

    onPlay() {
        // Curent track loading:
        if (this.props.isLoading) {
            return;
        }

        // Curent track is loaded, allow to pause/play:
        if (this.props.isPlaying || this.props.isPaused) {
            get('audio').togglePlay();

            return;
        }

        // Otherwise, load & play track:
        this.props.onPlay();
    }

    onSeek(position) {
        if (this.props.isLoading) {
            return;
        }

        if (this.props.isPlaying || this.props.isPaused) {
            this.props.onSeek(position * this.props.version.duration);

            return;
        }

        this.props.onPlayAt(position * this.props.version.duration);
    }

    onShowTags() {
        this.setState({ showTags: !this.state.showTags });
    }

    isNew(createdAt) {
        return new Date(createdAt) > new Date().getTime() - 1000 * 60 * 60 * 24 * 30;
    }

    render() {
        const {
            version,
            mobile,
            active,
            projectId,
            searchResultsPosition,
            isPlaying,
            isPaused,
            isLoading,
            read,
            showAllTags,
            playTime,
        } = this.props;
        const {
            reference,
            main,
            label,
            waveformUrl,
            duration,
            track,
            availableForSimilarSearch,
            adminEditUrl,
            highlights,
            audioFileUrl,
        } = version;

        const { album } = track;
        const { showTags } = this.state;

        return (
            <Anchor className={classnames('track', {
                'track--active': isPlaying,
                'track--paused': isPaused,
                'track--loading': isLoading,
                'track--read': read && !(isPlaying || isPaused || isLoading),
            })} id={reference} active={active}>
                <div className="track__main">
                    <div className="track__details">
                        <button
                            type="button"
                            className="cover"
                            style={{ backgroundImage: `url('${album.coverAsThumbnail}')` }}
                            onClick={this.onPlay}
                            aria-hidden="true"
                        />
                        <div className="details">
                            <button type="button" className="title" onClick={this.onPlay}>
                                <span className="sr-only">Lire</span>
                                {track.title}
                                {main ? null : ` - ${label}`}
                                {this.isNew(track.createdAt) ? <span className="chip">New</span> : ''}
                            </button>
                            <span className="info">
                                <TrackVersionProjects version={version} />
                                {main ? track.reference : reference}
                            </span>
                            {track.nbVersions > 1 ? <span><TrackVersionsButton trackReference={track.reference} count={track.nbVersions} /></span> : null}
                        </div>
                    </div>
                    <span className="track__waveform">
                        <Seeker onSeek={this.onSeek} className="seeker">
                            <WaveformByUrl reference={reference} audioFileUrl={audioFileUrl} waveformUrl={waveformUrl} progress={playTime / duration} duration={duration} highlights={highlights} light />
                        </Seeker>
                        <span className="length">
                            {formatDuration(duration)}
                        </span>
                    </span>
                    <span className="track__actions">
                        <TrackButtonBar
                            mobile={mobile}
                            versionReference={reference}
                            trackReference={track.reference}
                            albumReference={album.reference}
                            projectId={projectId}
                            searchResultsPosition={searchResultsPosition}
                            availableForSimilarSearch={availableForSimilarSearch}
                            onShowTags={this.onShowTags}
                            showTags={showTags}
                            adminEditUrl={adminEditUrl}
                        />
                    </span>
                </div>
                <div className="tack__more">
                    {showTags || showAllTags ? <TrackTags trackReference={track.reference} /> : null}
                </div>
            </Anchor>
        );
    }
}

export const TrackVersionPropTypes = TrackVersion.propTypes;

function TrackVersionWrapper({ isPlaying, ...remainingProps }) {
    const [playTime, setPlayTime] = useState(0);
    const audio = useAudioApi();

    const handlePlayTimeUpdated = useCallback(() => {
        const time = audio.getTime();
        setPlayTime(time);
    }, []);

    /**
     * Tracks & store the playtime in a component state,
     * by listening to the audio API events.
     */
    useEffect(() => {
        if (isPlaying) {
            audio.addEventListener('timeupdate', handlePlayTimeUpdated);
        }

        return () => audio.removeEventListener('timeupdate', handlePlayTimeUpdated);
    }, [handlePlayTimeUpdated, isPlaying]);

    return <TrackVersion {...remainingProps} isPlaying={isPlaying} playTime={playTime} />;
}

TrackVersionWrapper.propTypes = {
    isPlaying: PropTypes.bool.isRequired,
};


const TrackVersionContainer = connect(
    (state, props) => ({
        mobile: state.navigation.size < SIZE_LG,
        active: state.navigation.anchor === props.version.reference,
        isPlaying: isPlayingTrack(state, props.version.track.reference),
        isPaused: isPausedTrack(state, props.version.track.reference),
        isLoading: isLoadingTrack(state, props.version.track.reference),
        read: state.player.history.includes(props.version.reference),
        showAllTags: state.search.showTags,
    }),
    (dispatch, props) => ({
        onPlay: () => {
            let startPosition = null;
            if (props.version.highlights?.length) {
                startPosition = props.version.highlights[0].offset;
            }

            dispatch(playTrack(props.version.track.reference, props.version.reference, startPosition, props.version.highlights));

            if (props.dispatchTrackPlayedStat) {
                dispatch(trackPlayed(props.version.reference, props.hasStreamingFeature));
            }

            // Generate click analytics event:
            dispatch(click(
                InsightsEvent.TRACK_PLAY,
                props.version.track.reference,
                props.searchResultsPosition && props.trackedQueryID,
                props.searchResultsPosition,
            ));
        },

        onSeek: (offset) => {
            dispatch(seekTrack(offset));
        },

        onPlayAt: (offset) => {
            dispatch(playTrack(props.version.track.reference, props.version.reference, offset, props.version.highlights));

            if (props.dispatchTrackPlayedStat) {
                dispatch(trackPlayed(props.version.reference, props.hasStreamingFeature));
            }

            // Generate click analytics event:
            dispatch(click(
                InsightsEvent.TRACK_PLAY,
                props.version.track.reference,
                props.searchResultsPosition && props.trackedQueryID,
                props.searchResultsPosition,
            ));
        }
    }),
)(TrackVersionWrapper);

TrackVersionContainer.propTypes = {
    version: TrackVersion.propTypes.version,
    projectId: PropTypes.string,
    // Algolia Analytics tracking:
    trackedQueryID: PropTypes.string,
    searchResultsPosition: PropTypes.number,
    dispatchTrackPlayedStat: PropTypes.bool,
    hasStreamingFeature: PropTypes.bool,
};

TrackVersionContainer.defaultProps = {
    projectId: null,
    trackedQueryID: null,
    searchResultsPosition: null,
};

export default connect((state) => ({
    trackedQueryID: state.search.searchTracksQueryID,
    // Dispatch trackPlayed stat only if user is neither admin nor staff
    dispatchTrackPlayedStat: !state.authentication.permissions.isAdmin && !state.authentication.permissions.isStaff,
    hasStreamingFeature: state.authentication?.permissions?.hasStreamingFeature === true,
}))(TrackVersionContainer);
