import { useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import classnames from 'classnames';
import Button from '@App/components/ui/Button';
import LevelTwoTagFilter from './LevelTwoTagFilter';
import LabelsFilter from './LabelsFilter';
import i18n from 'i18n-js';
import { formatDuration } from '@App/utils';
import RangeSlider from '@App/components/search/advanced/RangeSlider';

export default function Filters({ tags, loading }) {
    const [tab, setTab] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState({ min: null, max: null });

    const onTab = (event) => {
        const id = event.currentTarget.value;
        setTab(prevTab => prevTab === id ? null : id);
    };

    const renderContent = () => {
        switch (tab) {
            case 'advanced':
                return (
                    <ul className="filters__column filters__column--large">
                        <li className="filters__item">
                            <span className="filters__label">
                                {i18n.t('search.advanced_filters.duration.label')}
                                <span>{formatDuration(selectedDuration.min)} - {formatDuration(selectedDuration.max)}</span>
                            </span>
                            <RangeSlider value={selectedDuration} onChange={setSelectedDuration} />
                        </li>
                    </ul>
                );

            case 'labels':
                return <LabelsFilter className="filters__column filters__column--large" />;

            default:
                return <LevelTwoTagFilter id={tab} className="filters__column filters__column--large" />;
        }
    };

    if (loading) {
        return <ul key="level-1" className="filters__column loading" />;
    }

    return (
        <>
            <ul className="filters__column">
                {tags.map(({ id, name }) => (
                    <li key={`${id}-item`} className={classnames('filters__item', { active: tab === id })}>
                        <Button key={`${id}-button`} label={name} onClick={onTab} value={id} className="" />
                    </li>
                ))}
                <li key="labels" className={classnames('filters__item', { active: tab === 'labels' })}>
                    <Button label={I18n.t('search.labels.label')} value="labels" onClick={onTab} className="" />
                </li>
                
                <li key="advanced" className={classnames('filters__item', { active: tab === 'advanced' })}>
                    <Button label={I18n.t('search.advanced_filters.label')} value="advanced" onClick={onTab} className="" />
                </li>
            </ul>
            {tab ? renderContent() : null}
        </>
    );
}

Filters.propTypes = {
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired
    ),
    loading: PropTypes.bool.isRequired,
};
