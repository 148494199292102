import I18n from 'i18n-js';
import BreadCrumb from '@App/components/navigation/BreadCrumb';
import AlbumList from '@App/components/model/album/AlbumList';

// @see https://www.apollographql.com/docs/react/pagination/cursor-based
export default function Albums() {
    return (
        <div className="content">
            <div className="container-fluid">
                <BreadCrumb dark />
                <section id="albums" className="thumblist">
                    <div className="thumblist__title">
                        <h1 className="h1">{I18n.t('page.library.albums.title')}</h1>
                    </div>
                    <AlbumList displayLabel={false} />
                </section>
            </div>
        </div>
    );
}
