import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import { get } from '@App/container';
import ShowProject from '@App/api/query/ShowProject.graphql';
import Query from '@App/components/api/Query';
import PageHeader from '@App/components/layout/PageHeader';
import Card from '@App/components/layout/Card';
import Button from '@App/components/ui/Button';
import TrackVersionList from '@App/components/model/TrackVersionList';
import EditableProjectTitle from '@App/components/model/project/EditableProjectTitle';
import ArchiveProjectButton from '@App/components/model/project/ArchiveProjectButton';
import RestoreProjectButton from '@App/components/model/project/RestoreProjectButton';
import ConvertToPlaylistButton from '@App/components/model/project/ConvertToPlaylistButton';
import DownloadProjectButton from '@App/components/model/project/DownloadProjectButton';
import ShareProjectButton from '@App/components/model/share/ShareProjectButton';
import FollowProjectButton from '@App/components/model/project/FollowProjectButton';
import UnfollowProjectButton from '@App/components/model/project/UnfollowProjectButton';
import Dropover from '@App/components/ui/Dropover';
import Loader from '@App/components/ui/Loader';
import ShowAllTagsButton from '@App/components/model/track/ShowAllTagsButton';
import DuplicateButton from '@App/components/model/project/DuplicateButton';
import { default as SubscriptionIncentiveBanner } from '@App/components/model/subscription/IncentiveBanner';

class Project extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        archived: PropTypes.bool.isRequired,
        createdAt: PropTypes.string.isRequired,
        coverAsHero: PropTypes.string,
        owned: PropTypes.bool.isRequired,
        canManageUser: PropTypes.bool.isRequired,
        canManagePlaylist: PropTypes.bool.isRequired,
        followed: PropTypes.bool.isRequired,
        authenticated: PropTypes.bool.isRequired,
        author: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
        versions: PropTypes.array.isRequired,
    };

    static MoreButton(props) {
        const { onClick, className } = props;
        const label = I18n.t('model.project.actions.other');

        return <Button icon="more" label={label} onClick={onClick} tooltip className={[className, 'action-button'].join(' ').trim()} />;
    }

    renderDropover() {
        const { MoreButton } = this.constructor;
        const { id } = this.props;

        return (
            <Dropover key="more" button={MoreButton}>
                {this.renderArchiveButton()}
                <ConvertToPlaylistButton id={id} className="link" />
                <DuplicateButton id={id} className="link" />
            </Dropover>
        );
    }

    renderArchiveButton() {
        const { id, archived, owned } = this.props;

        if (!owned) {
            return null;
        }

        return archived ? <RestoreProjectButton id={id} className="link" /> : <ArchiveProjectButton id={id} className="link link--danger" />;
    }

    renderFollowButton() {
        const { id, followed, authenticated } = this.props;

        if (!authenticated) {
            return null;
        }

        return followed ? <UnfollowProjectButton key="follow" id={id} className="btn" /> : <FollowProjectButton key="follow" id={id} className="btn" />;
    }

    render() {
        const { Image, Title, Description } = PageHeader;
        const { id, name, archived, createdAt, coverAsHero, versions, author, owned, canManageUser, canManagePlaylist } = this.props;
        const editable = owned && !archived;
        const displayDropover = owned || canManageUser || canManagePlaylist;
        const actions = [
            <ShowAllTagsButton key="show-tags" />,
            displayDropover ? this.renderDropover() : null,
            owned ? <ShareProjectButton key="share" projectId={id} className="btn" /> : this.renderFollowButton(),
            <DownloadProjectButton key="download" projectId={id} className="btn btn--primary" />,
        ];

        return (
            <div className="content">
                <PageHeader className={`header ${editable ? 'header-editable' : ''}`}>
                    <Card>
                        <div className="header__content">
                            <Image src={coverAsHero} alt={name}/>
                            <div className="info">
                                {editable ? <EditableProjectTitle id={id} name={name} /> : <Title>{name}</Title>}
                                <Description>
                                    <p>{I18n.t('model.project.author', { author: author.name })}</p>
                                    <p>{I18n.t('model.track.countTracks', { count: versions.length })}</p>
                                    <p>{I18n.t('model.project.createdAt', { date: I18n.l('date.formats.full', createdAt) })}</p>
                                </Description>
                            </div>
                        </div>
                        <div className="header__actions">
                            {actions}
                        </div>
                    </Card>
                </PageHeader>
                <div className="container-fluid">
                    <Card>
                        <SubscriptionIncentiveBanner />
                        <TrackVersionList versions={versions} projectId={editable ? id : undefined} />
                    </Card>
                </div>
            </div>
        );
    }
}

const ConnectedProject = connect((state) => ({
    authenticated: state.authentication.id !== null,
    canManageUser: state.authentication.permissions.canManageUser,
    canManagePlaylist: state.authentication.permissions.canManagePlaylist,
}))(Project);

function LoadingProject() {
    const { Title, Description } = PageHeader;

    return (
        <div className="content">
            <PageHeader>
                <Card>
                    <Title><Loader /></Title>
                    <Description />
                </Card>
            </PageHeader>
            <div className="container-fluid">
                <Card title="" loading />
            </div>
        </div>
    );
}

function MetaWrapper(props) {
    const { name, coverAsHero, author } = props;

    get('meta').set(
        I18n.t('meta.content.project.title', { name, author: author.name }),
        null,
        coverAsHero
    );

    return <ConnectedProject {...props} />;
}

MetaWrapper.propTypes = {
    name: PropTypes.string.isRequired,
    coverAsHero: PropTypes.string.isRequired,
    author: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }).isRequired,
};

function mapResults(data) {
    if (!data.project) {
        return null;
    }

    const { tracks, user, ...properties } = data.project;

    return {
        ...properties,
        author: {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
        },
        versions: tracks
    };
}

export default function ProjectWrapper(props) {
    const { id } = props;

    return <Query component={MetaWrapper} loadingComponent={LoadingProject} query={ShowProject} variables={{ id }} mapResult={mapResults} />;
}

ProjectWrapper.propTypes = {
    id: PropTypes.string.isRequired,
};
