import Select from '@App/components/form/Select';

const options = [
    {label: 'Contenus pour ma marque ou celle de mes clients (privé, public, association, freelance…)', value: 'branded_content'},
    {label: 'Projets cinématographiques (long métrage, court métrage, bande-annonce…)', value: 'movie'},
    {label: 'Films publicitaires (agence de publicité, de communication…)', value: 'advertising'},
    {label: 'Création de contenu (youtubeur, podcast…)', value: 'podcast'},
    {label: 'Programmes de stock (documentaire, fiction, série…)', value: 'stock_program'},
    {label: 'Programmes de flux (émission de plateau, actualité, météo…)', value: 'flow_program'},
    {label: 'Sonorisation d\'un établissement (ERP, restaurant, salle de sport…)', value: 'public_places'},
    {label: 'Autres', value: 'other'}
];

/**
 * Keep file in sync with the Enum `\App\Domain\Entity\User\Sector`
 */
export default function SelectUserSector(props) {
    return <Select {...props} options={options}/>;
}
