import { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Query from '@App/components/api/Query';
import Thumblist from '@App/components/layout/Thumblist';
import GetFeaturedPlaylists from '@App/api/query/GetFeaturedPlaylists.graphql';
import PlaylistTile from '@App/components/model/PlaylistTile';
import { PLAYLISTS } from '@App/routes';
import Link from '@App/components/navigation/Link';

class FeaturedPlaylists extends Component {
    static propTypes = {
        playlists: PropTypes.arrayOf(
            PropTypes.shape(PlaylistTile.propTypes).isRequired
        ).isRequired,
        seeAll: PropTypes.bool,
    };

    static defaultProps = {
        seeAll: false,
    };

    renderPlaylist(playlist) {
        return <PlaylistTile key={playlist.id} {...playlist} />;
    }

    renderSeeAll() {
        if (!this.props.seeAll) {
            return null;
        }

        return (
            <Link route={PLAYLISTS.name}>
                {I18n.t('page.library.featured-playlists.seeAll')}
            </Link>
        );
    }

    render() {
        const { playlists } = this.props;

        if (playlists.length < 1) {
            return null;
        }

        return (
            <Thumblist key="featured-playlist" id="featured-playlist" title={I18n.t('page.library.featured-playlists.title')} link={this.renderSeeAll()}>
                {playlists.map(this.renderPlaylist)}
            </Thumblist>
        );
    }
}

export default function FeaturedPlaylistsWrapper(props) {
    const { limit, ...childProps } = props;

    return <Query
        key="featured-playlist-query"
        query={GetFeaturedPlaylists}
        variables={{ limit }}
        component={FeaturedPlaylists}
        loadingComponent={FeaturedPlaylists}
        childProps={childProps}
        mapResult={data => ({ playlists: data.playlists })}
        mapLoading={() => ({ playlists: [] })}
    />;
}

FeaturedPlaylistsWrapper.propTypes = {
    limit: PropTypes.number,
};

FeaturedPlaylistsWrapper.defaultProps = {
    limit: 4,
};
