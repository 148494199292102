import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import I18n from 'i18n-js';
import { formatDurationWord } from '@App/utils';
import { removeDuration } from '@App/store/actions/search';

const getLabel = (minDuration, maxDuration) => {
    const min = minDuration ? formatDurationWord(minDuration) : null;
    const max = maxDuration ? formatDurationWord(maxDuration) : null;

    if (!min) {
        return I18n.t('search.duration.max', { max });
    }

    if (!max) {
        return I18n.t('search.duration.min', { min });
    }

    return I18n.t('search.duration.range', { min, max });
};

function SearchDuration({ min, max }) {
    const dispatch = useDispatch();

    return (
        <div className="search-items--item search-term">
            {getLabel(min, max)}
            <button className="search-items--remove" type="button" onClick={() => dispatch(removeDuration())}>
                <i className="icon icon--clear"></i>
            </button>
        </div>
    );
}

SearchDuration.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
};

SearchDuration.defaultProps = {
    min: null,
    max: null,
};

export default SearchDuration;
