import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import I18n from 'i18n-js';
import { removeBpm } from '@App/store/actions/search';

function SearchBpm({ min, max }) {
    const dispatch = useDispatch();

    const getLabel = (min, max) => {
        if (!min) {
            return I18n.t('search.bpm.max', { max });
        }

        if (!max) {
            return I18n.t('search.bpm.min', { min });
        }

        return I18n.t('search.bpm.range', { min, max });
    };

    return (
        <div className="search-items--item search-term">
            {getLabel(min, max)}
            <button className="search-items--remove" type="button" onClick={() => dispatch(removeBpm())}>
                <i className="icon icon--clear"></i>
            </button>
        </div>
    );
}

SearchBpm.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
};

SearchBpm.defaultProps = {
    min: null,
    max: null,
};

export default SearchBpm;
