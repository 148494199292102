import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isSearching } from '@App/store/reducers/search';
import { isSearching as isSimilaritySearching } from '@App/store/reducers/similarity';
import ResultList from '@App/components/page/library/ResultList';
import SimilarResultList from '@App/components/page/library/SimilarResultList';
import { default as SubscriptionIncentiveBanner } from '@App/components/model/subscription/IncentiveBanner';
import HomeLatestAlbums from '@App/components/model/HomeLatestAlbums';
import HomeFeaturedPlaylists from '@App/components/model/HomeFeaturedPlaylists';

class Library extends Component {
    static propTypes = {
        searching: PropTypes.bool.isRequired,
        similaritySearching: PropTypes.bool.isRequired,
    };

    renderContent() {
        const { searching, similaritySearching } = this.props;

        if (searching) {
            return (
                <div className='library-searching'>
                    <SubscriptionIncentiveBanner />
                    <ResultList />
                </div>
            );
        }

        if (similaritySearching) {
            return (
                <div className='library-searching'>
                    <SubscriptionIncentiveBanner />
                    <SimilarResultList />
                </div>
            );
        }

        return (
            <div className='library-default'>
                <SubscriptionIncentiveBanner />
                <HomeFeaturedPlaylists limit={5} />
                <HomeLatestAlbums limit={5} />
            </div>
        );
    }

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    {this.renderContent()}
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        searching: isSearching(state),
        similaritySearching: isSimilaritySearching(state),
    }),
)(Library);
