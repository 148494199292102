import { createRef, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import { LIBRARY } from '@App/routes';
import { navigate, openFilterModal } from '@App/store/actions/navigation';
import Search from '@App/components/search/Search';
import Backdrop from '@App/components/layout/Backdrop';
import SearchInput from '@App/components/search/SearchInput';
import SearchTag from '@App/components/search/searchbar/SearchTag';
import SearchTerm from '@App/components/search/searchbar/SearchTerm';
import SearchLabel from '@App/components/search/searchbar/SearchLabel';
import SearchDuration from '@App/components/search/searchbar/SearchDuration';
import SearchBpm from '@App/components/search/searchbar/SearchBpm';
import SearchTempo from '@App/components/search/searchbar/SearchTempo';
import SimilaritySearchUrl from '@App/components/search/searchbar/SimilaritySearchUrl';
import SimilaritySearchFile from '@App/components/search/searchbar/SimilaritySearchFile';
import SimilaritySearchTrack from '@App/components/search/searchbar/SimilaritySearchTrack';
import { addTerm } from '@App/store/actions/search';
import { setUrl, setFile } from '@App/store/actions/similarity';
import { SIZE_LG } from '@App/service/MobileWatcher';
import SimilaritySearchButton from '@App/components/search/SimilaritySearchButton';
import SimilarityInput from '@App/components/search/SimilarityInput';
import SimilarityDropBox from '@App/components/search/SimilarityDropBox';

class SearchBar extends Component {
    static propTypes = {
        mobile: PropTypes.bool.isRequired,
        openModal: PropTypes.func.isRequired,
        resultDisplayed: PropTypes.bool.isRequired,
        addTerm: PropTypes.func.isRequired,
        setSimilarityUrl: PropTypes.func.isRequired,
        setSimilarityFile: PropTypes.func.isRequired,
        dispayResults: PropTypes.func.isRequired,
        search: PropTypes.shape({
            terms: PropTypes.arrayOf(
                PropTypes.string.isRequired
            ).isRequired,
            includedTags: PropTypes.arrayOf(
                PropTypes.string.isRequired
            ).isRequired,
            excludedTags: PropTypes.arrayOf(
                PropTypes.string.isRequired
            ).isRequired,
            labels: PropTypes.arrayOf(
                PropTypes.string.isRequired
            ).isRequired,
            minDuration: PropTypes.number,
            maxDuration: PropTypes.number,
            minBpm: PropTypes.number,
            maxBpm: PropTypes.number,
            tempos: PropTypes.arrayOf(
                PropTypes.string.isRequired
            )
        }).isRequired,
        similaritySearch: PropTypes.shape({
            url: PropTypes.string,
            file: PropTypes.bool,
            track: PropTypes.string,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            search: null,
            similarityUrl: null,
            modeSimilarity: false,
        };

        this.similarityDropBox = createRef();

        this.onSearchTerm = this.onSearchTerm.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSearchClear = this.onSearchClear.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSimilarityUrlChange = this.onSimilarityUrlChange.bind(this);
        this.onSimilarityUrlClear = this.onSimilarityUrlClear.bind(this);
        this.onSimilarityUrl = this.onSimilarityUrl.bind(this);
        this.onSimilarityFile = this.onSimilarityFile.bind(this);
        this.onFind = this.onFind.bind(this);
        this.openSimilarity = this.openSimilarity.bind(this);
        this.closeSimilarity = this.closeSimilarity.bind(this);
    }

    static isActive(data) {
        return Object.values(data).some(value => value !== null);
    }

    componentDidUpdate(prevProps) {
        const { isActive } = this.constructor;
        const { search, similaritySearch } = this.props;

        if (isActive(search) && !isActive(prevProps.search)) {
            this.closeSimilarity();
        } else if (isActive(similaritySearch) && !isActive(prevProps.similaritySearch)) {
            this.openSimilarity();
        }
    }

    /**
     * Clear search
     */
    clear() {
        this.setSearch(null);
        this.setSimilarityUrl(null);

        if (this.similarityDropBox.current) {
            this.similarityDropBox.current.clear();
        }
    }

    /**
     * Set search current value (for text input)
     */
    setSearch(search) {
        this.setState({ search });
    }

    /**
     * Set similarity url current value (for text input)
     */
    setSimilarityUrl(similarityUrl) {
        this.setState({ similarityUrl });
    }

    openSimilarity() {
        this.setState({ modeSimilarity: true });
    }

    closeSimilarity() {
        this.setState({ modeSimilarity: false });
    }

    /**
     * Input text typed
     */
    onSearchChange(event) {
        this.setSearch(event.target.value);
    }

    /**
     * On search clear button
     */
    onSearchClear() {
        this.setSearch(null);
    }

    /**
     * Input text typed
     */
    onSimilarityUrlChange(event) {
        this.setSimilarityUrl(event.target.value);
    }

    /**
     * On clear button
     */
    onSimilarityUrlClear() {
        this.setSimilarityUrl(null);
    }

    /**
     * Add current term and go the result page
     */
    onSearchTerm() {
        // No search term provided
        if (typeof this.state.search !== 'string') {
            return;
        }

        this.props.addTerm(this.state.search.trim());
        this.onSearch();
    }

    /**
     * Add similarity url and go to the result page
     */
    onSimilarityUrl() {
        // No url provided
        if (typeof this.state.similarityUrl !== 'string') {
            return;
        }

        this.props.setSimilarityUrl(this.state.similarityUrl.trim());
        this.onSearch();
    }

    /**
     * Add similarity file and go to the result page
     */
    onSimilarityFile(file) {
        this.props.setSimilarityFile(file);
        this.onSearch();
    }

    /**
     * On search: tag, term, similar url or similar file provided
     */
    onSearch() {
        const { resultDisplayed, dispayResults } = this.props;

        if (!resultDisplayed) {
            dispayResults();
        }

        this.clear();
    }

    /**
     * Non-result item has been selected: Playlist, Album, ...
     */
    onFind() {
        this.clear();
    }

    renderFilterButton() {
        const { openModal, resultDisplayed, mobile } = this.props;

        if (!(mobile && resultDisplayed)) {
            return null;
        }

        return (
            <button className="searchbar__button filter-button" onClick={openModal}>
                <i className="icon icon--filter" aria-hidden="true"></i>
                <span className="sr-only">{I18n.t('search.filters.label')}</span>
            </button>
        );
    }

    renderSimilaritySearchItem() {
        const { file, url, track } = this.props.similaritySearch;

        if (url) {
            return <SimilaritySearchUrl url={url} />;
        }

        if (file) {
            return <SimilaritySearchFile file={file} />;
        }

        if (track) {
            return <SimilaritySearchTrack track={track} />;
        }

        return null;
    }

    renderSearchBar() {
        const { mobile } = this.props;
        const {
            terms,
            includedTags,
            excludedTags,
            labels,
            minDuration,
            maxDuration,
            minBpm,
            maxBpm,
            tempos
        } = this.props.search;
        const { search } = this.state;

        return (
            <Fragment>
                <div className="searchbar">
                    <SearchInput onChange={this.onSearchChange} onClear={this.onSearchClear} value={search}>
                        <div className="search-items">
                            {terms.map(term => <SearchTerm key={term} term={term} />)}
                            {includedTags.map(id => <SearchTag key={id} id={id} />)}
                            {excludedTags.length > 0 ? <span className="search-items--item search-items--note">{I18n.t('search.tag.without')}</span> : null}
                            {excludedTags.map(id => <SearchTag key={id} id={id} excluded />)}
                            {labels.length > 0 ? <span className="search-items--item search-items--note">{I18n.t('search.tag.labels')}</span> : null}
                            {labels.map(reference => <SearchLabel key={reference} reference={reference} />)}
                            {minDuration || maxDuration ? <SearchDuration min={minDuration} max={maxDuration} /> : null}
                            {minBpm || maxBpm ? <SearchBpm min={minBpm} max={maxBpm} /> : null}
                            {tempos?.map?.(id => <SearchTempo key={id} id={id} />)}
                        </div>
                    </SearchInput>
                    {this.renderFilterButton()}
                    <SimilaritySearchButton onClick={this.openSimilarity} />
                </div>
                <div className="search-box search-box--show">
                    <Search
                        search={search}
                        onSearch={this.onSearch}
                        onFind={this.onFind}
                        onSubmit={this.onSearchTerm}
                        includedTags={includedTags}
                        excludedTags={excludedTags}
                        mobile={mobile}
                        limit={6}
                        minChars={3}
                    />
                </div>
            </Fragment>
        );
    }

    renderSimilarityBar(item) {
        const { similarityUrl } = this.state;

        return (
            <div key="similaritybar" className="similarity__bar">
                <button type="button" className="search-button" onClick={this.closeSimilarity}>
                    <i className="icon icon--search" aria-hidden="true" />
                </button>
                <SimilarityInput
                    onSubmit={this.onSimilarityUrl}
                    onChange={this.onSimilarityUrlChange}
                    onClear={this.onSimilarityUrlClear}
                    value={similarityUrl}
                >
                    {item ? this.renderSimilarityBarFilters(item) : null}
                </SimilarityInput>
            </div>
        );
    }

    renderSimilarityBarFilters(item) {
        const { includedTags, excludedTags, labels, minDuration, maxDuration, minBpm, maxBpm, tempos } = this.props.search;

        return <div className="search-items">
            {item}
            {includedTags.map(id => <SearchTag key={id} id={id} />)}
            {excludedTags.length > 0 ? <span className="search-items--item search-items--note">{I18n.t('search.tag.without')}</span> : null}
            {excludedTags.map(id => <SearchTag key={id} id={id} excluded />)}
            {labels.length > 0 ? <span className="search-items--item search-items--note">{I18n.t('search.tag.labels')}</span> : null}
            {labels.map(reference => <SearchLabel key={reference} reference={reference} />)}
            {minDuration || maxDuration ? <SearchDuration min={minDuration} max={maxDuration} /> : null}
            {minBpm || maxBpm ? <SearchBpm min={minBpm} max={maxBpm} /> : null}
            {tempos?.map?.(id => <SearchTempo key={id} id={id} />)}
        </div>;
    }

    render() {
        const { modeSimilarity, search } = this.state;
        const item = modeSimilarity ? this.renderSimilaritySearchItem() : null;

        return (
            <Fragment>
                {modeSimilarity ? this.renderSimilarityBar(item) : this.renderSearchBar()}
                <SimilarityDropBox
                    key="similarity-box"
                    active={modeSimilarity && item === null}
                    onDrag={this.openSimilarity}
                    onFile={this.onSimilarityFile}
                    ref={this.similarityDropBox}
                />
                <Backdrop key="backdrop" active={(search !== null && search !== '') || (modeSimilarity && item === null)} />
            </Fragment>
        );
    }
}

export default connect(
    state => ({
        mobile: state.navigation.size < SIZE_LG,
        resultDisplayed: state.navigation.route === LIBRARY.name,
        search: {
            terms: state.search.terms,
            includedTags: state.search.includedTags,
            excludedTags: state.search.excludedTags,
            labels: state.search.labels,
            minDuration: state.search.duration.min,
            maxDuration: state.search.duration.max,
            minBpm: state.search.bpm.min,
            maxBpm: state.search.bpm.max,
            tempos: state.search.tempos,
        },
        similaritySearch: {
            url: state.similarity.url,
            file: state.similarity.file,
            track: state.similarity.track,
        },
    }),
    dispatch => ({
        dispayResults: () => dispatch(navigate(LIBRARY.name)),
        addTerm: term => dispatch(addTerm(term)),
        setSimilarityUrl: url => dispatch(setUrl(url)),
        setSimilarityFile: file => dispatch(setFile(file)),
        openModal: () => dispatch(openFilterModal()),
    })
)(SearchBar);
