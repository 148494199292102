import BpmFilter from '@App/components/search/advanced/BpmFilter';
import DurationFilter from '@App/components/search/advanced/DurationFilter';
import TempoFilter from '@App/components/search/advanced/TempoFilter';

export default function Filters() {
    return (
        <div>
            <div className="search-filters">
                <DurationFilter minRange={0} maxRange={720} />
                <BpmFilter minRange={0} maxRange={380} />
                <TempoFilter />
            </div>
        </div>
    );
}
