import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import i18n from 'i18n-js';
import RangeSlider from '@App/components/search/advanced/RangeSlider';
import { setBpm as setBpmAction } from '@App/store/actions/search';
import { split, join } from './utils';

const BPM_OPTIONS = [
    { value: '0;60', label: '< 60' },
    { value: '60;90', label: '60 - 90' },
    { value: '90;120', label: '90 - 120' },
    { value: '120;180', label: '120 - 180' },
    { value: '180;300', label: '180 - 300' },
    { value: '300;380', label: '> 300' },
];

export default function BpmFilter({ minRange, maxRange }) {
    const storedBpm = useSelector(state => state.search.bpm);
    
    const [selectedBpm, setSelectedBpm] = useState({
        min: storedBpm.min,
        max: storedBpm.max
    });
    
    const dispatch = useDispatch();
    const storeBpm = useCallback(
        // Send null max if selected max matches max range
        ({ min, max }) => dispatch(setBpmAction(min === minRange ? null : min, max === maxRange ? null : max)),
        [dispatch, minRange, maxRange],
    );
    
    // get updates from the store
    useEffect(() => {
        if ((storedBpm.max ?? maxRange) !== (selectedBpm.max ?? maxRange) || (storedBpm.min ?? minRange) !== (selectedBpm.min ?? minRange)) {
            setSelectedBpm(storedBpm);
        }
    // dont rerender on state update, else you'll have an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedBpm]);

    return (
        <div className="filters__inline">
            <span className="filters__label">
                {i18n.t('search.advanced_filters.bpm.label')}
                <span>{selectedBpm.min} - {selectedBpm.max}</span>
            </span>
            <RangeSlider
                value={selectedBpm}
                min={minRange}
                max={maxRange}
                step={10}
                onChange={setSelectedBpm}
                onChangeComplete={storeBpm}
            />

            <div className="filters__inline__radios">
                {BPM_OPTIONS.map(({ label, value }) => (
                    <label 
                        key={value}
                        className={classNames('chip chip--filters', { 
                            'chip--active': join(selectedBpm) === value,
                        })} 
                        style={{ cursor: 'pointer' }}
                    >
                        <input 
                            type="radio"
                            name="bpm" 
                            value={value}
                            checked={join(selectedBpm) === value}
                            hidden
                            onChange={e => setSelectedBpm(split(e.target.value))}
                        />
                        {label}
                    </label>
                ))}
            </div>
        </div>
    );
}