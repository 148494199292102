import {useQuery} from '@apollo/client';
import { ALBUMS } from '@App/routes';
import I18n from 'i18n-js';
import GetAlbums from '@App/api/query/GetAlbums.graphql';
import { Album } from '@App/api/types/Library';
import Link from '@App/components/navigation/Link';
import Loader from '@App/components/ui/Loader';
import AlbumCard from '@App/components/model/album/AlbumCard';

interface Props {
  limit: number
}

export default function HomeLatestAlbums({
  limit = 5,
}: Props) {
  const queryOptions = {
    variables: { first: limit, after: null, target: 'homepage' },
    notifyOnNetworkStatusChange: true,
  };

  const { data, loading} = useQuery(GetAlbums, queryOptions);
  const albums = (data?.publicAlbums?.edges || []).map((edge) => edge.node);

  const link = <Link route={ALBUMS.name}>
    {I18n.t('page.library.homepage.latest-albums.seeAll')}
  </Link>;

  return (
    <section className="thumblist">
      <div className="thumblist__title">
        <h2 className="h1">{I18n.t('page.library.homepage.latest-albums.title')}</h2>
        {link}
      </div>
      <div className="thumblist__row">
        {(loading) && <Loader/>}
        {albums.map((album) => renderAlbumAsCard(album))}
      </div>
    </section>
  );
}

function renderAlbumAsCard(album: Album) {
  return <AlbumCard key={album.reference} album={album} displayLabel={false} />;
}
