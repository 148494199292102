import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from 'i18n-js';
import { showTags } from '@App/store/actions/search';

const ShowAllTagsButton = ({ active, showTags }) => {
    const onClick = () => {
        showTags(!active);
    };

    return (
        <div onClick={onClick} className='switch-input'>
            <span 
                className={`switch ${active ? 'switch--active' : ''}`}
                data-active="#2C2C2C"
                data-inactive="#757575" 
                data-accent="#fff"
                aria-label={I18n.t('search.all_tags.' + (active ? 'hide' : 'show'))}
                role="switch"
                aria-checked={active}
            >
                <span className="switch__handle" />
            </span>
            
            <span style={{ width: '14ch' }}>{I18n.t('search.all_tags.' + (active ? 'hide' : 'show'))}</span>
        </div>
    );
};

ShowAllTagsButton.propTypes = {
    active: PropTypes.bool.isRequired,
    showTags: PropTypes.func.isRequired,
};

export default connect(
    state => ({
        active: state.search.showTags,
    }),
    dispatch => ({
        showTags: value => dispatch(showTags(value)),
    })
)(ShowAllTagsButton);
