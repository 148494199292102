import {useQuery} from '@apollo/client';
import { PLAYLISTS, PLAYLIST } from '@App/routes';
import I18n from 'i18n-js';
import GetFeaturedPlaylists from '@App/api/query/GetFeaturedPlaylists.graphql';
import { Playlist } from '@App/api/types/Library';
import Tile from '@App/components/layout/Tile';
import Link from '@App/components/navigation/Link';
import Loader from '@App/components/ui/Loader';

interface Props {
  limit: number
}

export default function HomeFeaturedPlaylists({
  limit = 5,
}: Props) {
  const queryOptions = {
    variables: { limit: limit },
    notifyOnNetworkStatusChange: true,
  };

  const { data, loading} = useQuery(GetFeaturedPlaylists, queryOptions);
  const playlists = data?.playlists || [];

  const link = <Link route={PLAYLISTS.name}>
    {I18n.t('page.library.homepage.featured-playlists.seeAll')}
  </Link>;

  return (
    <section className="thumblist">
      <div className="thumblist__title">
        <h2 className="h1">{I18n.t('page.library.homepage.featured-playlists.title')}</h2>
        {link}
      </div>
      <div className="thumblist__row">
        {(loading) && <Loader/>}
        {playlists.map((playlist) => renderPlaylistAsTile(playlist))}
      </div>
    </section>
  );
}

function renderPlaylistAsTile(playlist: Playlist) {
  const link = {
    route: PLAYLIST.name,
    parameters: { id: playlist.id, slug: playlist.slug },
  };

  return <Tile key={playlist.id} title={playlist.title} cover={playlist.coverAsLargeThumbnail} link={link}/>;
}
