import { relayStylePagination } from '@apollo/client/utilities';

export default {
    typePolicies: {
        Album: { keyFields: ['reference'] },
        Country: { keyFields: ['code'] },
        Label: { keyFields: ['reference'] },
        Playlist: { keyFields: ['slug'] },
        Track: { keyFields: ['reference'] },
        TrackVersion: { keyFields: ['reference'] },
        License: { keyFields: ['id'] },
        Order: { keyFields: ['id'] },
        UserPermission: {
            // User permission are always replaced when loaded:
            merge: false,
        },

        // Relay-style pagination for queries
        // https://www.apollographql.com/docs/react/pagination/cursor-based/#relay-style-cursor-pagination
        Query: {
            fields: {
                /* eslint-disable no-inline-comments */
                // The provided keys are the ones that vary between different search queries.
                // https://www.apollographql.com/docs/react/pagination/core-api/#defining-a-field-policy
                searchTracks: relayStylePagination(['search', 'filters']), // SearchTracks, LatestTracks
                similarTracksByUrl: relayStylePagination(['url', 'filters']), // SearchSimilarTracksByUrl
                similarTracksByFile: relayStylePagination(['file', 'id', 'filters']), // SearchSimilarTracksByFile
                similarTracksByReference: relayStylePagination(['reference', 'filters']), // SearchSimilarTracksByReference
                licenceRequests: relayStylePagination(), // MyLicenceRequests
                publicAlbums: relayStylePagination(['label', 'target']),
                /* eslint-enable */

                cart: {
                    // Do not attempt to merge previous with incoming data on writing new cart data:
                    // See updateCartWithNewData
                    merge: false,
                },
            },
        },
    }
};
