import { Fragment } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Loader from '@App/components/ui/Loader';
import Button from '@App/components/ui/Button';
import {useQuery} from '@apollo/client';
import GetAlbums from '@App/api/query/GetAlbums.graphql';
import AlbumCard from '@App/components/model/album/AlbumCard';

// @see https://www.apollographql.com/docs/react/pagination/cursor-based
export default function AlbumList({
    labelReference,
    listClassName,
    initialSize,
    chunkSize,
    displayLabel
}) {
    let queryOptions = {
        variables: { first: initialSize, after: null },
        notifyOnNetworkStatusChange: true
    };
    if (null !== labelReference) {
        queryOptions.variables = {
            ...queryOptions.variables,
            label: labelReference
        };
    }
    const { data, loading, fetchMore } = useQuery(GetAlbums, queryOptions);
    const albums = (data?.publicAlbums?.edges || []).map((edge) => edge.node);
    const pageInfo = data?.publicAlbums?.pageInfo;

    function onLoadMore() {
        if (pageInfo?.hasNextPage !== true) {
            return;
        }
        let fetchVariables = {
            after: pageInfo?.endCursor,
            first: chunkSize
        };
        if (null !== labelReference) {
            fetchVariables = {
                ...fetchVariables,
                label: labelReference
            };
        }

        fetchMore({
            variables: fetchVariables
        });
    }
    return (
        <Fragment>
            <div className={listClassName}>
                {albums.map(album => <AlbumCard key={album.reference} album={album} displayLabel={displayLabel} />)}
            </div>
            { (loading) && <Loader /> }
            { !loading && pageInfo?.hasNextPage === true && <Button
                label={ I18n.t('page.library.albums.actions.loadMore', {chunkSize})}
                onClick={onLoadMore}
            /> }
        </Fragment>
    );
}

AlbumList.propTypes = {
    labelReference: PropTypes.string,
    listClassName: PropTypes.string.isRequired,
    initialSize: PropTypes.number.isRequired,
    chunkSize: PropTypes.number.isRequired,
    displayLabel: PropTypes.bool.isRequired
};

AlbumList.defaultProps = {
    labelReference: null,
    listClassName: 'thumblist__row',
    initialSize: 150,
    chunkSize: 30,
    displayLabel: true
};
