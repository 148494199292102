import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { removeTempo } from '@App/store/actions/search';
import GetTempos from '@App/api/query/GetTempos.graphql';
import Loader from '@App/components/ui/Loader';
import Query from '@App/components/api/Query';

function SearchItem({ tempo }) {
    return tempo?.name ? <span>{tempo.name}</span> : <Loader />;
}

export default function SearchTempo({ id }) {
    const dispatch = useDispatch();
    
    const remove = () => {
        dispatch(removeTempo(id));
    };

    return (
        <div className="search-items--item search-tag">
            <Query
                component={SearchItem}
                query={GetTempos}
                mapResult={data => ({ tempo: data?.tempos?.find?.(tempo => tempo.id === id) })}
                mapLoading={() => ({ tempo: null })}
            />
            <button type="button" className="search-items--remove" onClick={remove}>
                <i className="icon icon--clear"></i>
            </button>
        </div>
    );
}

SearchTempo.propTypes = {
    id: PropTypes.string.isRequired,
};
