import {connect} from 'react-redux';
import { SUBSCRIPTION } from '@App/routes';

interface Props {
    display: boolean
}

function IncentiveBanner({
  display,
}: Props) {
  return display && (
    <div className="subscription-banner">
      <div className="subscription-banner__header">
        <h3>Téléchargez des musiques pour vos vidéos !</h3>
      </div>

      <div className="subscription-banner__body">
        <p>Explorez notre catalogue musical pour vos projets audiovisuels à destination des réseaux sociaux ou pour une utilisation commerciale dans le monde entier.</p>
      </div>

      <div className="subscription-banner__footer">
        <a href={SUBSCRIPTION.getUrl()} className="btn btn--secondary flex-1 md:flex-none" draggable="false">
          <span className="hidden md:inline">Essayer gratuitement pendant 7 jours</span>
          <span className="md:hidden inline">Essayer gratuitment</span>
        </a>
        <a href="https://www.musique-music.com/fr/tarifs" className="btn btn--primary" draggable="false">Tarifs</a>
      </div>
    </div>
  );
}

export default connect((state) => ({
  // @ts-expect-error authentication is not found in state
  display: state.authentication?.permissions?.displaySubscriptionBanner === true,
}))(IncentiveBanner);
